import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useConstCallback } from "powerhooks/useConstCallback";
import cx from "classnames";
import type { KcContext } from "../../kcContext";
import { setCurrentYear } from "../../modules/year";
import "./index.scss";
import { ReturnProSvgLarge } from "../../icons/ReturnProSvgLarge";
import { useZendeskRedirect } from "./useZendeskRedirect";
import { getQueryParam } from "../utils/getQueryParam";

const REDIRECT_URI_PARAM = "redirect_uri";
const EMAIL_URI_PARAM = "email";
const IMPERSONATION_LOCAL_STORAGE_KEY = "impersonationTargetUser";

localStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY);

export type KcContext_Login = Extract<KcContext, { pageId: "login.ftl" }>;

export const Login = memo(({ kcContext }: { kcContext: KcContext_Login }) => {
  const { url, message, realm, client, social } = kcContext;
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const redirectUri = useMemo(
    () => getQueryParam(window.location.href, REDIRECT_URI_PARAM),
    []
  );

  let email: string | null = useMemo(
    () => getQueryParam(redirectUri || window.location.href, EMAIL_URI_PARAM),
    [redirectUri]
  );

  useEffect(() => {
    if (email) {
      setUsername(email);
      setPassword("");
    }
  }, [email]);

  useEffect(() => {
    if (realm.name) {
      localStorage.setItem("kcRealm", realm.name);
    }
  }, [realm]);

  const { isReturnProLoginRendered } = useZendeskRedirect({
    clientId: kcContext.client.clientId,
  });

  const signUpUrl =
    "https://" + (client.baseUrl || "").split("/")[2] + "/sign-up";

  useEffect(() => {
    if (isReturnProLoginRendered) {
      setCurrentYear();
    }
  }, [isReturnProLoginRendered]);

  useEffect(() => {
    if (isReturnProLoginRendered) {
      if (notificationRef.current && message) {
        setShowNotification(true);
        notificationRef.current.innerHTML = message.summary;
      }

      if (notificationRef.current && !message && email) {
        setShowNotification(true);
        notificationRef.current.innerHTML =
          "Thank you, your email has been successfully verified. Please proceed to log in";
      }
    }
  }, [message, isReturnProLoginRendered, email]);

  const onSubmit = useConstCallback(() => {
    setDisabled((prev) => !prev);
    setLoading((prev) => !prev);
  });

  if (isReturnProLoginRendered === false) {
    return null;
  }

  return (
    <div className="container">
      <main className="main">
        <div className="form-wrapper" id="form-wrapper">
          <div className="logo-large">
            <ReturnProSvgLarge />
          </div>
          <h1>Sign in to ReturnPro</h1>
          <div
            className={cx(
              "notification",
              !message && email
                ? `notification--success`
                : `notification--${message?.type ?? "error"}`,
              showNotification && "notification--show"
            )}
            id="notification"
          >
            <div className="notification__icon"></div>
            <div className="notification__message">
              <div
                ref={notificationRef}
                className="notification__message-title"
              ></div>
            </div>
          </div>
          <form
            className="form"
            id="kc-form-login"
            onSubmit={onSubmit}
            action={url.loginAction}
            method="post"
          >
            <div className="form__fields">
              <div className="form__field">
                <label>
                  Username or Email
                  <input
                    id="username"
                    className="input"
                    type="text"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setUsername(event.target.value)
                    }
                  />
                </label>
              </div>
              <div className="form__field">
                <label>
                  Password
                  <input
                    id="password"
                    className="input"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(event.target.value)
                    }
                  />
                </label>
              </div>
            </div>
            <div className="form__buttons">
              <button
                className={`button button--main ${
                  loading && "button--loading"
                }`}
                id="button-login-submit"
                type="submit"
                disabled={disabled}
              >
                Sign in
              </button>
            </div>

            {social.providers?.length && (
              <div className="form__social-buttons">
                <div>Or sign in with</div>

                <div className="form__social-buttons-list">
                  {social.providers.map(({ displayName, loginUrl }) => (
                    <button
                      key={displayName}
                      className="button button--secondary"
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = loginUrl;
                      }}
                    >
                      {displayName}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {realm.resetPasswordAllowed && (
              <a className="link" href={url.loginResetCredentialsUrl}>
                Forgot username or password?
              </a>
            )}

            <div className="form__no-account-caption">
              {realm.resetPasswordAllowed && (
                <>
                  Do not have an account?
                  <a className="link" href={signUpUrl}>
                    Register now
                  </a>
                </>
              )}
            </div>
          </form>
        </div>
      </main>

      <footer className="footer">
        <div className="copyright">
          <div className="copyright__text">
            &#169; <span id="year">2023</span> All Rights Reserved. ReturnPro ®
            is a
            <br />
            registered trademark of The Recon Group, LLP.
          </div>
        </div>
      </footer>
    </div>
  );
});
